
/**
 * Module definition and dependencies
 */
angular.module('Shared.ClubDomain.Modal', [])

/**
 * Config
 */
.config($modalProvider => {
  $modalProvider.modal('clubDomain', {
    templateUrl: 'modals/club-domain.html',
    controller: 'ModalClubDomainCtrl',
    closeOnClick: false,
    resolve: {
      club($store) {
        'ngInject';
        return $store.club.get();
      },
      user($store) {
        'ngInject';
        return $store.user.get();
      },
    },
  });
})

/**
 * Controller
 */
.controller('ModalClubDomainCtrl', function(
  $controller, $modalInstance
) {

  //Get controllers
  const $ctrl = this;
  const $base = $controller('ModalCtrl', {$modalInstance});

  //Extend
  angular.extend($ctrl, $base);

  /**
   * On init
   */
  this.$onInit = function() {

    //Set data
    this.isAdmin = this.user && this.user.isAdmin;
  };

  this.onClose = function() {
    $modalInstance.close();
  };
});
