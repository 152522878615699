
/**
 * Module definition and dependencies
 */
angular.module('App.Component', [
  'App.MaintenanceCheck.Init',
  'App.ApiVersionInterceptor.Service',
  'App.Core.Controller',
  'App.Controller',
  'App.Header.Component',
])

/**
 * Application configuration
 */
.config((
  Config, $locationProvider, $urlServiceProvider, $apiProvider, $logProvider,
  $stateProvider, $urlMatcherFactoryProvider, $httpProvider, $animateProvider,
  $compileProvider
) => {

  //Extract config
  const {api, debug} = Config;

  //Enable HTML 5 mode browsing and set default route
  $locationProvider.html5Mode(true);
  $urlServiceProvider.rules.otherwise('/error/page-not-found');
  $urlMatcherFactoryProvider.strictMode(false);

  //Mark as safe
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|tel|sms):/);

  //Push api version interceptor
  $httpProvider.interceptors.push('ApiVersionInterceptor');

  //Configure API
  $apiProvider.setBaseUrl(api.baseUrl);
  $apiProvider.setDefaultModel('$baseModel');
  $apiProvider.setEnforceDataFormat(true);
  $apiProvider.setConfig('timeout', api.networkTimeout * 1000);
  $apiProvider.setVerbose(debug.logVerbose);

  //Configure animate provider
  $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);

  //Disable all console logging if needed
  if (!debug.logToConsole) {
    $logProvider.disable('all');
  }

  //App base state, for the actual application (e.g. when logged in)
  $stateProvider.state('app', {
    url: '',
    abstract: true,
    component: 'appRoute',
    data: {
      auth: true,
    },
  });
})

/**
 * Route component
 */
.component('appRoute', {
  templateUrl: 'app/app.html',
  controller: 'AppCtrl',
  bindings: {
    club: '<',
    user: '<',
    system: '<',
    pages: '<',
    integrations: '<',
    activities: '<',
  },
})

/**
 * Run logic
 */
.run((
  $log, $modal, $notice, $timeout, $state, $trace, $document,
  $animate, $transitions, $window, $store, $http, $location,
  Config, Page, Interface, Kiosk, Auth, Pagination, Modules,
  ScrollPosition, GlobalScope, Intercom, Domain, YesNo
) => {

  //Extract config
  const {hostname} = $window.location;
  const params = $location.search();
  const {
    env, app, api, debug, dateFormat, inputLimit, image, fileUpload,
  } = Config;

  //Check cookies
  let cookieMatch = false;
  try {
    const cookie = document.cookie || '';
    cookieMatch = cookie.match(/club=([a-z0-9-]+)/);
  }
  catch (error) {
    //Fall through
  }

  //Set default headers
  $http.defaults.headers.common['X-Hostname'] = hostname;
  $http.defaults.headers.common['X-Version'] = app.revision;
  $http.defaults.headers.common['X-Api-Version'] = api.version;
  if (params.club) {
    $http.defaults.headers.common['X-Club'] = params.club;
  }
  else if (cookieMatch) {
    $http.defaults.headers.common['X-Club'] = cookieMatch[1];
  }

  //Log to console
  $log.log(`${app.title} v${app.version}–${app.revision} (${env}), running on ${Domain.isGeneric ? 'generic domain' : 'dedicated domain'}`);
  $log.log(`API version ${api.version}`);

  //Helper vars
  let kioskTimeout = null;
  let loadingTimeout = null;
  let preloader = angular.element($document[0].getElementById('Preloader'));

  //Enable transition trace
  if (debug.logTransitions) {
    $trace.enable('TRANSITION');
  }

  //Configure intercom
  if (Config.intercom) {
    $window.intercomSettings = {
      app_id: Config.intercom.id,
      custom_launcher_selector: '.IntercomLauncher',
      hide_default_launcher: true,
    };
  }

  //Set in global scope
  GlobalScope.set({env, app, dateFormat, inputLimit, image, fileUpload, YesNo});

  //Don't accept drop/dragover events except for in our drop zones
  function preventDefault(event) {
    event.preventDefault();
  }
  $window.addEventListener('dragover', preventDefault, false);
  $window.addEventListener('drop', preventDefault, false);

  //Predefine filter option
  Page.defineOption('filter', {
    title: 'Filter',
    icon: 'filter_list',
    click() {
      Page.toggleFilters();
    },
  });

  /**
   * Club resolve hook
   */
  $transitions.onBefore({
    to: (state) => !state.name.match(/^error|signupClub/),
  }, transition => {

    //This is done here instead of at the top level app route resolves, as it
    //may have been refreshed. So we get it from the store all the time.
    transition.addResolvable({
      token: 'club',
      resolveFn: () => $store.club.get(),
    });
  });

  /**
   * Authenticated data resolve hook
   */
  $transitions.onBefore({
    to: (state) => (state.data && state.data.auth),
  }, transition => {
    transition.addResolvable({
      token: 'user',
      resolveFn: () => $store.user.get(),
    });
    transition.addResolvable({
      token: 'system',
      resolveFn: () => $store.system.get(),
    });
    transition.addResolvable({
      token: 'pages',
      resolveFn: () => $store.pages.query({isVisible: true}),
    });
    transition.addResolvable({
      token: 'activities',
      resolveFn: () => $store.activities.query(),
    });
    transition.addResolvable({
      token: 'integrations',
      resolveFn: () => $store.integrations.query(),
    });
  });

  /**
   * Before hook for transitions
   */
  $transitions.onBefore({}, transition => {

    //Get module ID
    const to = transition.to();
    const from = transition.from();

    //Add resolvable alias for $transition$
    transition.addResolvable({
      token: 'transition',
      data: transition,
      resolveFn: () => transition,
    });

    //Add resolvable alias for Page
    transition.addResolvable({
      token: 'page',
      data: Page,
      resolveFn: () => Page,
    });

    //Remember scroll position before leaving and block from restoring
    ScrollPosition.remember();
    ScrollPosition.block();

    //Ignore rest if navigating to error page
    if (to.name.match(/^error|signupClub/)) {
      return;
    }

    //Refresh if coming from club signup page
    const refresh = (from.name && from.name.match(/^signupClub/));

    //Make sure club is loaded and resolved
    return $store.club.get(refresh);
  }, {priority: 100});

  /**
   * On before hook for transitions to a module with an ID
   */
  $transitions.onBefore({
    to(state) {
      return (state.data && state.data.id);
    },
  }, transition => {

    //Get module ID
    const to = transition.to();
    const {id} = to.data;

    //Module not present?
    if (!Modules.has(id)) {
      $log.log(`Module ${id} not enabled or loaded`);
      if (id !== 'home') {
        return transition.router.stateService.target('home');
      }
      else {
        return transition.router.stateService.target('login');
      }
    }

    //Get module and extract roles
    const module = Modules.find(id);
    const roles = to.data.roles || module.roles;

    //Add as resolvable
    transition.addResolvable({
      token: 'module',
      policy: {when: 'EAGER'},
      data: module,
      resolveFn: () => module,
    });

    //Not authenticated, don't check user
    if (!Auth.isAuthenticated()) {
      return;
    }

    //Get user
    return $store.user
      .get()
      .then(user => {

        //Check roles
        if (roles && roles.length && (!user || !user.hasRole(...roles))) {
          $log.log(`No access to ${id} module`);
          return transition.router.stateService.target('home');
        }
      });
  }, {priority: 90});

  /**
   * On before hook for transitions to a route with roles
   */
  $transitions.onBefore({
    to(state) {
      return (state.data && state.data.roles);
    },
  }, transition => {

    //Get roles
    const to = transition.to();
    const {roles} = to.data;

    //Not authenticated, don't check user
    if (!Auth.isAuthenticated()) {
      return;
    }

    //Get user
    return $store.user
      .get()
      .then(user => {

        //Check roles
        if (roles && roles.length && (!user || !user.hasRole(...roles))) {
          $log.log(`No access to route`);
          return transition.router.stateService.target('home');
        }
      });
  }, {priority: 80});

  /**
   * On before hook to check club plan hasn't expired
   */
  $transitions.onBefore({}, transition => {

    //Get to state
    const to = transition.to();
    const {openCard} = transition.params();
    if (to.name === 'admin.club' && openCard) {
      return;
    }

    //Not authenticated, ignore
    if (!Auth.isAuthenticated()) {
      return;
    }

    //Get club and user
    return Promise.all([
      $store.club.get(),
      $store.user.get(),
    ])
      .then(([club, user]) => {
        if (!club || Config.supressPlanWarnings) {
          return;
        }
        else if (club && club.isPartnerAccount) {
          return;
        }
        else if (!user || !user.isAccountOwner) {
          return;
        }
        else if (club.hasTrialEnded) {
          $timeout(() => $modal.open('trialEnded', {locals: {club}}));
        }
        else if (club.hasPlanExpired) {
          $timeout(() => $modal.open('planExpired', {locals: {club}}));
        }
        else if (club.isPlanExpiringSoon) {
          $timeout(() => $modal.open('planExpiringSoon', {locals: {club}}));
        }
      });
  });

  /**
   * On before hook to validate email links
   */
  $transitions.onBefore({}, transition => {

    //Not authenticated, don't check user
    if (!Auth.isAuthenticated()) {
      return;
    }

    //Get user ID, if not present, ignore
    const {u: userId} = $location.search();
    if (!userId) {
      return;
    }

    //Get user
    return $store.user
      .get()
      .then(user => {

        //Get params
        const params = transition.params();

        //Check if user ID given in link
        if (user) {
          if (userId !== user.id) {

            //Check for invalid user param to prevent endless loops
            if (!params.invalidUser) {
              $log.log(`Invalid user`);
              return transition.router.stateService
                .target('home', {invalidUser: true});
            }
          }

          //Clear param if user does match
          else {
            $location.search('u', null);
          }
        }
      });
  });

  //Flag for club domain modal
  let clubDomainShown = false;

  /**
   * Start hook for transitions
   */
  $transitions.onStart({}, transition => {

    //Get from state
    const from = transition.from();

    //Close all open modals, unless club frozen modal
    if (!$modal.isOpen('clubFrozen')) {
      $modal.closeAll();
    }

    if (!clubDomainShown) {
      $store.club.get().then(club => {
        if (club && club.hasDomain && Domain.isGeneric) {
          $store.user.get().then(user => {
            if (user && user.isAdmin) {
              $modal.open('clubDomain', {locals: {club}});
              clubDomainShown = true;
            }
          });
        }
      });
    }

    //If we're ok to transition, create a timeout for displaying the loading bar
    //Only show it when we're not in our initial loading cycle, because we have
    //another preloader for that.
    if (!loadingTimeout && from.name && !from.data.noSpinner) {
      loadingTimeout = $timeout(() => {
        $notice.showLoading();
      }, 250);
    }
  });

  /**
   * On finish hook for transitions
   */
  $transitions.onFinish({}, () => {

    //Clear loading timeout
    if (loadingTimeout) {
      $timeout.cancel(loadingTimeout);
      loadingTimeout = null;
    }

    //Clear loading notice
    $notice.hideLoading();

    //Remove preloader
    $timeout(() => {
      if (preloader) {
        $animate.leave(preloader);
        preloader = null;
      }
    });
  });

  /**
   * Error hook for transitions
   */
  $transitions.onError({}, transition => {

    //Hide loading notice
    $notice.hideLoading();

    //Revert the url when the transition was triggered by a URL sync
    //See: https://github.com/angular-ui/ui-router/issues/3416
    const options = transition.options();
    if (options.source === 'url') {
      const $state = transition.router.stateService;
      const $urlRouter = transition.router.urlRouter;
      if ($state.$current.navigable) {
        $urlRouter.push($state.$current.navigable.url, $state.params, {
          replace: true,
        });
        $urlRouter.update(true);
      }
    }
  });

  /**
   * Success hook for transitions
   */
  $transitions.onSuccess({}, transition => {

    //Get to transition
    const to = transition.to();

    //Reset page properties
    Page.reset();

    //Scroll to top
    Interface.scrollToTop();

    //Remember page state, restore scroll position
    Pagination.setState(to.name);
    ScrollPosition.setState(to.name);
    ScrollPosition.restore();

    //Cancel any existing kiosk timeouts
    if (kioskTimeout) {
      $timeout.cancel(kioskTimeout);
    }

    //Update intercom
    Intercom.update();

    //If kiosk mode is enabled, go back to that state after a minute
    if (Kiosk.isEnabled()) {
      kioskTimeout = $timeout(() => {
        const state = Kiosk.getDefaultPage();
        const isOnKiosk = ($state.current.name === 'kiosk');
        const isOnRegistration = $state.current.name.match(/^register\./);
        const isOnVisitorLog = $state.current.name.match(/^visitor\./);
        if (!Auth.isAuthenticated()) {
          if (
            state === 'kiosk' &&
            !isOnKiosk && !isOnVisitorLog && !isOnRegistration
          ) {
            $state.go(state);
          }
          else if (state.match(/^visitor\./) && !isOnVisitorLog) {
            $state.go(state);
          }
          else if (state.match(/^register\./) && !isOnRegistration) {
            $state.go(state);
          }
        }
      }, 60 * 1000);
    }
  }, {priority: 100});
});
